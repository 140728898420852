<template>
  <div class="jingcai">
    <headbar :title="$t('哈希竞猜')" :right-text="$t('竞猜记录')" @rightClick="() => { tolink('JingcaiRecord') }"></headbar>

    <div class="content">
      <div class="radio">
        <van-radio-group v-model="type" direction="horizontal" icon-size="18px">
          <van-radio name="0" checked-color="#e56efa">{{ $t('猜小') }}</van-radio>
          <van-radio name="1" checked-color="#e56efa">{{ $t('猜大') }}</van-radio>
          <van-radio name="2" checked-color="#e56efa">{{ $t('猜单') }}</van-radio>
          <van-radio name="3" checked-color="#e56efa">{{ $t('猜双') }}</van-radio>
        </van-radio-group>
      </div>

      <div class="title">{{ $t('竞猜金额') }}</div>
      <div class="input-wrap">
        <q-input borderless type="number" v-model="jine" dense :input-style="{ color: '#fff' }" style="width: 100%;"
          :placeholder="$t('请输入竞猜金额')" />
      </div>
      <div class="title">{{ $t('支付方式') }}</div>
      <van-radio-group v-model="payWay" direction="horizontal" icon-size="16px">
        <van-radio name="LBD" checked-color="#e56efa">
          <div class="consume-title">LBD</div>
        </van-radio>
        <van-radio name="WLBD" checked-color="#e56efa">
          <div class="consume-title">WLBD</div>
        </van-radio>
      </van-radio-group>

      <div class="btn" @click="submit">{{ $t('确定') }}</div>
      <div class="yongjin">{{ $t('哈希佣金') }}：{{ yongjin }}</div>

      <div style="margin: 20px 0;color: #fff;">
        <div class="title row justify-between">
          {{ $t('最近竞猜结果') }}
          <span class="link-text" @click="tolink('destroyRecords')">{{ $t('竞猜销毁记录') }}</span>
        </div>
        <div class="row">
          <div class="col table-th" style="text-decoration: underline;" @click="toqukuai">{{ $t('区块高度') }}</div>
          <div class="col table-th">{{ $t('区块hash') }}</div>
          <div class="col table-th">{{ $t('区块结果') }}</div>
          <div class="col table-th">{{ $t('时间') }}</div>
        </div>
        <div class="scroll-list q-mt-xs">
          <div v-for="(item, index) in list" :key="index" style="display: flex;padding-bottom: 5px;">
            <div class="col">{{ item.blockNumber > 0 ? item.blockNumber : '' }}</div>
            <div class="col">{{ abbr(item.blockHash, 2, 4) }}</div>
            <div class="col">
              <span v-if="item.state == 0" style="color: #e02020;">{{ $t('待开奖') }}</span>
              <span v-if="item.state == 1" style="color: green;">{{ $t('已开奖') }} | {{ item.resultValueNumber }}</span>
            </div>
            <div class="col">{{ item.resultTime }}</div>
          </div>
        </div>
        <div class="down">
          {{ $t('倒计时') }}：<van-count-down :time="countDown" format="ss" @finish="getdata" />s
        </div>

        <van-tabs v-model="active" background="transparent" color="#e96dff" title-active-color="#e96dff"
          title-inactive-color="#fff" @change="tabsChange">
          <van-tab name="0" :title="$t('单双走势')"></van-tab>
          <van-tab name="1" :title="$t('大小走势')"></van-tab>
        </van-tabs>
        <div class="grid" ref="gridRef">
          <div class="grid-lie" v-for="(item, index) in gridList" :key="index">
            <template v-if="active == 0">
              <div class="grid-ge" v-for="(item2, index2) in 13" :key="index2">
                <div class="scball b1" v-if="item[index2] == '单'">{{ $t('单') }}</div>
                <div class="scball b2" v-else-if="item[index2] == '双'">{{ $t('双') }}</div>
                <view class="scball" v-else>{{ item[index2] }}</view>
              </div>
            </template>
            <template v-if="active == 1">
              <div class="grid-ge" v-for="(item2, index2) in 13" :key="index2">
                <div class="scball b1" v-if="item[index2] == '大'">{{ $t('大') }}</div>
                <div class="scball b2" v-else-if="item[index2] == '小'">{{ $t('小') }}</div>
                <view class="scball" v-else>{{ item[index2] }}</view>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="article">
        <!-- <div v-html="articleData"></div> -->
        <p style="color: rgb(194, 79, 74);">{{ $t('玩法规则') }}</p>
        <p>{{ $t('玩法规则内容') }}</p>
        <p>
          <font color="#8baa4a">0、1、2、3、4</font> {{ $t('为') }} <font color="#c24f4a">{{ $t('小') }}</font><br />
          <font color="#8baa4a">5、6、7、8、9</font> {{ $t('为') }} <font color="#c24f4a">{{ $t('大') }}</font><br />
          <font color="#8baa4a">1、3、5、7、9</font> {{ $t('为') }} <font color="#c24f4a">{{ $t('单') }}</font><br />
          <font color="#8baa4a">0、2、4、6、8</font> {{ $t('为') }} <font color="#c24f4a">{{ $t('双') }}</font>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { RadioGroup, Radio, CountDown, Tab, Tabs } from 'vant';
import Web3Helper from "../../assets/js/web3helper.js";
import CustomComponent from "@/components/CustomComponent";

import ExtractChineseWords from "@/assets/js/language/tool/extract-chinese-words.js";
import {  } from 'vant';
export default {
  name: '',
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [CountDown.name]: CountDown
  },
  setup() {
    return {
      us: ref(null),
      touziOrderId: ref(""),
      type: ref("0"),
      jine: ref(""),
      payWay: ref("LBD"),
      list: ref([]),

      articleData: ref(""),

      danshuang: ref([]),
      daxiao: ref([]),
      active: ref(0),
      gridList: ref([]),

      yongjin: ref(0),

      countDown: ref(0)
    }
  },
  created() {
    this.getdata()
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.$route.query.touziOrderId) {
      this.touziOrderId = this.$route.query.touziOrderId
    }
    this.getArticle()
    this.getRewardList()
  },
  methods: {
    formatDateTime(dateTimeString) {
      const dateTime = new Date(dateTimeString);
      const month = String(dateTime.getMonth() + 1).padStart(2, '0');
      const day = String(dateTime.getDate()).padStart(2, '0');
      const hours = String(dateTime.getHours()).padStart(2, '0');
      const minutes = String(dateTime.getMinutes()).padStart(2, '0');
      return `${month}-${day} ${hours}:${minutes}`;
    },
    getTitle(title) {
      let result = ""
      let arr = ExtractChineseWords(title)
      arr.forEach(item => {
        result += this.$i18n.t(item)
      });
      return result
    },
    toqukuai() {
      window.open("https://bscscan.com/")
    },
    tabsChange(e) {
      this.active = e
      if (e == 0) {
        this.gridList = this.danshuang
      } else {
        this.gridList = this.daxiao
      }
      this.$nextTick(() => {
        this.scrollRight()
      })
    },
    tolink(path) {
      this.$router.push(path)
    },
    getRewardList() {
      let _this = this
      this.$request.post(
        "api/JingcaiSetting/GetPool",
        {},
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data
          _this.yongjin = data.biliChi
        }
      )
    },
    submit() {
      let _this = this
      if (this.jine == "") {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请输入竞猜金额"],
            persistent: true,
          }
        });
        return
      }
      this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: ["确定要竞猜吗?"],
          persistent: true,
          showCancel: true
        }
      }).onOk(() => {
        let web3Helper = new Web3Helper();
        this.$q.loading.show({
          message: _this.$i18n.t("竞猜下注中...")
        })
        web3Helper.getSign().then((signStr) => {
          _this.$request.post(
            "api/JingcaiOrder/Xiazhu",
            {
              
              userid: _this.us.userid,
              touziOrderId: _this.touziOrderId,
              type: _this.type,
              jine: _this.jine,
              signStr,
              address: _this.us.address,
              payWay: _this.payWay
            },
            (res) => {
              _this.$q.loading.hide();
              let msg = ""
              let msgList = ExtractChineseWords(res.data.msg)
              msgList.forEach(item => {
                msg += _this.$i18n.t(item)
              });
              _this.$q.dialog({
                component: CustomComponent,
                componentProps: {
                  messages: [msg],
                  persistent: true,
                }
              });
              if (res.data.code == 100) {
                _this.jine = ""
              }
            }
          )
        }).catch(() => {
          _this.$q.loading.hide();
        })
      }
      )
    },
    onFinish() {
      setInterval(() => {

      }, 3000);
    },
    scrollRight() {
      let clientWidth = this.$refs.gridRef.clientWidth
      let scrollWidth = this.$refs.gridRef.scrollWidth
      if (scrollWidth > clientWidth) {
        this.$refs.gridRef.scrollLeft = scrollWidth
      }
    },
    structureData(data) {
      let xiao = [0, 1, 2, 3, 4]
      let dan = [1, 3, 5, 7, 9]
      let danshuang = []
      let daxiao = []
      data.forEach(item => {
        if (item.state == 1) {
          // 大小
          let xiaoIndex = xiao.findIndex(item2 => item2 == item.resultValueNumber)
          let text = xiaoIndex != -1 ? "小" : "大"
          if (daxiao.length == 0) {
            daxiao[daxiao.length] = []
            daxiao[daxiao.length - 1].push(text)
          } else {
            if (daxiao[daxiao.length - 1][0] == text) {
              daxiao[daxiao.length - 1].push(text)
            } else {
              daxiao[daxiao.length] = []
              daxiao[daxiao.length - 1].push(text)
            }
          }
          // 单双
          let danshuangIndex = dan.findIndex(item2 => item2 == item.resultValueNumber)
          let text2 = danshuangIndex != -1 ? "单" : "双"
          if (danshuang.length == 0) {
            danshuang[danshuang.length] = []
            danshuang[danshuang.length - 1].push(text2)
          } else {
            if (danshuang[danshuang.length - 1][0] == text2) {
              danshuang[danshuang.length - 1].push(text2)
            } else {
              danshuang[danshuang.length] = []
              danshuang[danshuang.length - 1].push(text2)
            }
          }
        }
      })
      this.danshuang = danshuang.reverse()
      this.daxiao = daxiao.reverse()
      this.gridList = this.active == 0 ? this.danshuang : this.daxiao
      this.$nextTick(() => {
        this.scrollRight()
      })
    },
    getdata() {
      let _this = this
      _this.loading = true
      _this.$request.post(
        "api/Jingcai/List",
        {
          pageIndex: _this.pageIndex,
          pageSize: 100,
          state: 99
        },
        (res) => {
          _this.loading = false
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data
          data.forEach(item => {
            if (item.state == 1) {
              item.resultValueNumber = item.resultValue.split('|')[0]
            }
            item.resultTime =  item.resultTime ? _this.formatDateTime(item.resultTime) : ''
          })
          // 倒计时
          let current = data.find(item => item.state == 0)
          if(current) {
            _this.countDown = new Date(current.endTime).getTime() - new Date(current.serverTime * 1000).getTime()
          }

          _this.structureData(data)
          _this.list = data
          console.log(data)
        }
      )
    },
    getArticle() {
      let _this = this
      _this.$request.post(
        "api/Article/Get",
        {
          select_id: 10,
        },
        (res) => {
          _this.articleData = res.data.data.articlecontent;
        }
      )
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
  }
}
</script>

<style scoped>
.jingcai {
  padding: 10px;
  min-height: 100vh;
  background-color: #080535;
}

.content {
  position: relative;
  min-height: calc(100vh - 70px);
  padding: 0 15px 0;
  background-color: #332d45;
}

.radio {
  padding: 20px 0;
}

:deep(.van-radio__label) {
  color: #fff !important;
}

.title {
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  color: #fff;
}

.input-wrap {
  padding: 0 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #221c35;
}

.btn {
  margin-top: 20px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  border-radius: 10px;
}

.article {
  color: #fff;
  padding: 20px 0 10px;
}

.grid {
  margin-top: 15px;
  display: flex;
  overflow: auto hidden;
  border-radius: 12px;
  background: linear-gradient(173deg, rgba(73, 73, 98, .31), rgba(53, 52, 86, .58));
  border: 1px solid #767595;
}

.grid-ge {
  padding: 5px;
  border: 1px solid hsla(0, 0%, 100%, .2);
}

.scball {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
}

.b1 {
  background: linear-gradient(180deg, #ff6393, #e73131);
}

.b2 {
  background: linear-gradient(180deg, #47ddc2, #0da271);
}

.scroll-list {
  max-height: 208px;
  overflow-y: auto;
}

.yongjin {
  margin-top: 10px;
  color: #e56efa;
  text-align: right;
}

.link-text {
  color: #e56efa;
  text-decoration: underline;
  font-size: 14px;
}

.table-th {
  color: #F6BF42;
  font-weight: bold;
}

.down {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #F6BF42;
  font-size: 15px;
}

.down :deep(.van-count-down) {
  color: #F6BF42;
  font-size: 15px;
}
</style>